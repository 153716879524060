import request from '@/utils/request'


// 查询系统通告列表
export function listMessage(query) {
  return request({
    url: '/message/messages/list',
    method: 'get',
    params: query
  })
}

// 查询系统通告分页
export function pageMessage(query) {
  return request({
    url: '/message/messages/page',
    method: 'get',
    params: query
  })
}

// 查询系统通告详细
export function getMessage(data) {
  return request({
    url: '/message/messages/detail',
    method: 'get',
    params: data
  })
}

// 新增系统通告
export function addMessage(data) {
  return request({
    url: '/message/messages/add',
    method: 'post',
    data: data
  })
}

// 修改系统通告
export function updateMessage(data) {
  return request({
    url: '/message/messages/edit',
    method: 'post',
    data: data
  })
}

// 删除系统通告
export function delMessage(data) {
  return request({
    url: '/message/messages/delete',
    method: 'post',
    data: data
  })
}
