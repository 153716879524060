<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">

      <a-form-model-item label="标题" prop="title" >
        <a-input v-model="form.title" :maxLength='15' placeholder="请输入标题" />
      </a-form-model-item>
      <a-form-model-item label="标题英文" prop="titleEn" >
        <a-input v-model="form.titleEn" :maxLength='50' placeholder="请输入标题英文" />
      </a-form-model-item>
      <a-form-model-item label="图片封面" prop="imgUrl" >
        <a-upload

          listType="picture-card"
          class="avatar-uploader"
          v-model="form.imgUrl"
          :showUploadList="false"
          :beforeUpload="beforeUpload"
          :customRequest="handleChange"
        >
          <plus-outlined />
          <img v-if="form.imgUrl" :src="form.imgUrl" alt="logo"
               style="height:104px;max-width:300px"/>
          <div v-else>

            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="内容" prop="content" >
        <j-editor editor-id='contentZh' v-model="form.content" />
      </a-form-model-item>
      <a-form-model-item label="内容英文" prop="contentEn" >
<!--        <editor id='contentEn' v-model="form.contentEn" />-->
        <j-editor editor-id='contentEn' v-model="form.contentEn" />
      </a-form-model-item>


      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>


<script>

import { getMessage, addMessage, updateMessage } from '@/api/message/message'
// import Editor from '@/components/Editor'
import JEditor from '@/components/Editor/JEditor.vue'
import UploadFileToVOD from '@/api/vod'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    // Editor,
    JEditor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        type: null,
        title: null,
        titleEn: null,
        content: null,
        contentEn: null,
        imgUrl: null,
        createTime: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      uploadLoading:false,
      rules: {
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        titleEn: [
          { required: true, message: '标题英文不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
        contentEn: [
          { required: true, message: '内容英文不能为空', trigger: 'blur' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        type: null,
        title: null,
        titleEn: null,
        content: null,
        contentEn: null,
        imgUrl: null,
        createTime: null,
        remark: null,
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getMessage({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    beforeUpload: function (file) {
      var fileType = file.type;
      if (fileType.indexOf('image') < 0) {
        this.$message.warning('请上传图片');
        this.uploadLoading=false
        return false;
      }
      this.form.configValue=""
      //TODO 驗證文件大小
    },
    handleChange(info) {

      let that = this
      this.uploadLoading = true;
      new UploadFileToVOD([info.file], {
        success: function (fileName) {
          that.form.imgUrl = fileName[0];//文件名
          that.uploadLoading = false;
        },
      });
      that.uploadLoading = false;

    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateMessage(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addMessage(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
